.navWrapper {
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  min-width: 100vw;
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 10;
}

.active {
  border-bottom: 2px solid white;
  color: white !important;
  font-weight: 300;
  text-decoration: none;
  -webkit-filter: drop-shadow(3px 3px 3px black);
  filter: drop-shadow(3px 3px 3px black);
}

.nav {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ul { 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 0;
  width: 100%;
}

.li {
  cursor: pointer;
  font-weight: 200;
  letter-spacing: 3px;
  list-style-type: none;
  padding-top: 0.5rem;
}

.link {
  border-radius: 2px;
  color: white;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
  transition: all 0.25s ease-in;
}

.link:hover {
  background-color: rgba(0,0,0,0.5);
  border-bottom: 1px solid white;
  color: white;
}

@media (max-width: 575px) {
  .li {
    letter-spacing: normal;
  }
}

@media (max-width: 374px) {
  .ul { 
    gap: 0;
  }
  .s{
    border: 1px solid white;
    border-radius: 4px;
  }
  .tanley {
    display: none;
  }
}